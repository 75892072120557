/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

body {
  background-color: #f0f2f5;
  color: #222;
  font-size: 16px;
  line-height: 1.6;
  opacity: 0;
  animation: fadeIn 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
  backdrop-filter: blur(8px);
}

.container {
  padding: 40px;
  max-width: 1100px;
  margin: 0 auto;
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s ease-in-out;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: white;
}

.hidden {
  opacity: 0;
  transform: translateY(50px); /* Inicialmente fuera de vista */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Transiciones más suaves */
}

.show {
  opacity: 1;
  transform: translateY(0); /* Entra en vista suavemente */
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s ease-in-out;
}

.header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #000;
  letter-spacing: -0.5px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  transition: color 0.5s cubic-bezier(0.86, 0, 0.07, 1), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.header h1:hover {
  color: #007aff;
  transform: scale(1.1);
}

.header button {
  background: linear-gradient(45deg, #007aff, #00c4ff);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out, transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0 8px 16px rgba(0, 122, 255, 0.2);
}

.header button:hover {
  background: linear-gradient(45deg, #005bb5, #009edb);
  transform: scale(1.15) rotate(-1deg);
}

.header button:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 122, 255, 0.4);
  transform: scale(1.07);
}

/* Input */
input[type="text"] {
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 1rem;
  margin-bottom: 30px;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

input[type="text"]:focus {
  border-color: #007aff;
  box-shadow: 0 0 14px rgba(0, 122, 255, 0.5);
}

/* Cards */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.card {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.5s cubic-bezier(0.86, 0, 0.07, 1), transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: relative;
  backdrop-filter: blur(10px);
}

.card:hover {
  box-shadow: 0 14px 35px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px) scale(1.03);
}

.card-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #007aff;
  transition: color 0.5s cubic-bezier(0.86, 0, 0.07, 1), transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  text-shadow: 1px 1px 5px rgba(0, 122, 255, 0.3);
}

.card:hover .card-title {
  color: #005bb5;
  transform: scale(1.1);
}

.card-content {
  color: #444;
  margin-bottom: 20px;
  line-height: 1.8;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0.9;
}

.card:hover .card-content {
  opacity: 1;
  transform: translateY(-4px);
}

/* Buttons */
button {
  background: linear-gradient(45deg, #007aff, #00c4ff);
  color: white;
  border: none;
  padding: 12px 18px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 6px 12px rgba(0, 122, 255, 0.2);
  transition: background-color 0.5s cubic-bezier(0.86, 0, 0.07, 1), transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

/* Smaller font size for Explore button */
.explore-button {
  font-size: 0.875rem;  /* Adjust the size to your preference */
}

button:hover {
  background: linear-gradient(45deg, #005bb5, #009edb);
  transform: translateY(-6px) scale(1.05);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 122, 255, 0.4);
}

/* Footer de las tarjetas */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

/* Animación de entrada (fade-in) */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .header button {
    padding: 10px 18px;
    font-size: 0.9rem;
  }

  input[type="text"] {
    padding: 12px;
  }
}

/* Categoría: Herramientas Educativas */
.educational-tools {
  background-color: #4682b4; /* Steel Blue */
  color: rgb(140, 219, 163);
}

.educational-tools .card-title {
  color: #7fe1bd; /* Royal Blue */
}

.educational-tools:hover {
  background-color: #5f9ea0; /* Cadet Blue */
}

/* Categoría: Herramientas Empresariales */
.business-tools {
  background-color: #ffffff; /* Coral */
  color: rgb(107, 158, 223);
}

.business-tools .card-title {
  color: #4879ed; /* Orange Red */
}

.business-tools:hover {
  background-color: #e4e8efc5; /* Tomato */
}
